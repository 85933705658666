
/** 
 * Marca o tipo da importacao, no sendido de saber qual modelo de planilha usamos.
*/

export enum ImportationSourceEnum {
	CONAGUA = 'CONAGUA',
}

export enum ImportationSourceLabelEnum {
	CONAGUA = 'CONAGUA',
}
