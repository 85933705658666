
/** 
 * Quando importamos um arquivo, cria-se um registro de validacao(validationmode = true). ele nasce com status IN_PROGRESS(1) e quando finaliza vai para o status CONCLUDED(2)
 * 
 * E agora temos dois cenarios IMPORTAR ou CANCELAR: 
 * 
 * 1) Quando clica em IMPORTAR esse registro de validacao muda para VALIDATION_IMPORT_CONCLUDED(3) e 
 * eh criado um novo registro de importacao (validationmode = false) que nasce IN_PROGRESS(1) e quando com finaliza vai para CONCLUDED(2)
 * 
 * 2) Quando clica em CANCELAR esse registro de validacao muda para VALIDATION_IMPORT_CANCELLED(4) e nada mais acontece.
 * 
*/

export enum ImportationLogStatusEnum {

	DELETED = 'DELETED', // 0
	IN_PROGRESS='IN_PROGRESS', // 1 
	CONCLUDED='CONCLUDED', // 2
	VALIDATION_IMPORT_CONCLUDED = 'VALIDATION_IMPORT_CONCLUDED', // 3
	VALIDATION_IMPORT_CANCELLED = 'VALIDATION_IMPORT_CANCELLED', // 4 Não usamos mais no Aquatec 2.0
	
}

export enum ImportationLogStatusLabelEnum {
    
	DELETED = 'Removido', // 0
	IN_PROGRESS='Em progresso', // 1 
	CONCLUDED='Concluído', // 2
	VALIDATION_IMPORT_CONCLUDED='Validado', // 3
	VALIDATION_IMPORT_CANCELLED='Validado e cancelado' // 4
}
