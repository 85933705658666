import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import React from 'react'
import { MimeTypeEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/MimeTypeEnum'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { UploadFileCP } from 'submodules/nerit-framework-ui/common/components/file/upload-file/UploadFileCP'

interface ICPProps {
    onUpload: (file: File) => void
    loading?: boolean
}

/**
 */
export function UploadStepICP(props: ICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <UploadTitleSCP>
                <TextCP
                    wrap={true}
                    strong={true}
                    text={'Faça upload do seu arquivo no formato CSV'}
                />
            </UploadTitleSCP>

            <UploadFileCP
                multiple={false}
                fileTypes={[MimeTypeEnum.XLSX]}
                loading={props.loading}
                loadingMsg={'Realizando upload e verificando dados da planilha'}
                uploadFiles={props.onUpload}
                customHint={'Apenas arquivos .xlsx são aceitos'}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`

const UploadTitleSCP = styled.div`
    margin-top: 30px;
    margin-bottom: 10px;
`
