import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { ImportationSourceEnum } from 'submodules/nerit-aquatec-sdk/services/data-import/enums/ImportationSourceEnum'

export class SamplingImportFormModel extends FormModel {

    @IsRequired()
    type: ImportationSourceEnum

    @IsRequired()
    samplingCode: number

    @IsRequired()
    id: string

    constructor(initialData?: Partial<SamplingImportFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
