import React from 'react'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { ImportationSourceEnum, ImportationSourceLabelEnum } from 'submodules/nerit-aquatec-sdk/services/data-import/enums/ImportationSourceEnum'

interface ICPProps {
    value?: ImportationSourceEnum
    isRequired?: boolean
    onChange?: (value: ImportationSourceEnum) => void
    width?: number
    fieldName?: string
    formStateManager?: IFormStateManager<any>
}

export function SelectSamplingImportTypeCP(props: ICPProps): JSX.Element {

    return (
        <SelectCP
            label={'Tipo de Importação'}
            required={!!props.isRequired}
            options={Object.values(ImportationSourceEnum).map((nature) => ({
                value: nature,
                label: ImportationSourceLabelEnum[nature]
            }))}
            onChange={props.onChange}
            width={props.width}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName}
        />
    )
}

