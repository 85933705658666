import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import React from 'react'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'

/**
 */
export function SamplingImportInstructionsCP(): JSX.Element {

    return (
        <>
            <CardCP title={'1. Formate sua planilha'}>
                <InstructionsSummarySCP>
                    <p>
                        Para que possamos importar com precisão os dados das amostras, é necessário que você formate sua planilha para o nosso modelo. <b>Faça o download da planilha de modelo</b> no botão abaixo:
                    </p>
                    <p>
                        <b>Atente-se</b> para as seguintes observações:
                    </p>

                    <ul>
                        <li>
                            O arquivo deve possuir a extensão <b>.xlsx</b>;
                        </li>
                        <li>
                            Seu arquivo pode ter diversas abas, mas será importado somente o conteúdo contido na aba nomeada <b>dados</b>;
                        </li>
                        <li>
                            Os <b>nomes das colunas</b> devem ser exatamente os mesmos descritos na planilha modelo.
                        </li>
                    </ul>

                    <ButtonCP
                        onClick={() => window.open('https://s3.sa-east-1.amazonaws.com/aquatec-bucket.nerit.com.br/system_util/Exemplo_Conagua_Final.xlsx', '_blank')}
                    >
                        Download de planilha modelo CONAGUA
                    </ButtonCP>
                </InstructionsSummarySCP>
            </CardCP>

            <CardCP title={'2. Se atente no formato de preenchimento'}>
                <p>
                    Preencha o arquivo com os dados de cada amostra. Veja como preencher cada coluna:
                </p>
                <ul>
                    <li>Data no formato dia/mes/ano, ex.: 21/12/2016;</li>
                    <li>Natureza com um dos valores seguintes: Água superficial, Água subterrânea, Água potável,
                        Efluente ou Processo;
                    </li>
                    <li>Itens classificados como <strong>presente</strong> ou <strong>ausente</strong> devem ter seus
                        campos preenchidos <strong>exatamente</strong> como "Presente" ou "Virtualmente presente" e
                        "Ausente" ou "Virtualmente ausente"
                    </li>
                </ul>
            </CardCP>
        </>
    )
}

const InstructionsSummarySCP = styled.div`
  font-size: .85rem;
  margin-bottom: 20px;
  margin-top: -2px;

  button {
    width: 100%;
    margin-top: 20px;

    span {
      text-align: center;
      width: 100%;
    }
  }
`
