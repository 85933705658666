import React, { useEffect, useState } from 'react'
import { UploadStepICP } from 'modules/samplings/components/modal-sampling-import/inner/UploadStepICP'
import { BasicModalCPProps, ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { SamplingImportFormModel } from 'modules/samplings/components/modal-sampling-import/inner/SamplingImportFormModel'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { SelectSamplingImportTypeCP } from 'modules/samplings/components/select-sampling-import-type/SelectSamplingImportTypeCP'
import { SelectSamplingsCP } from 'modules/samplings/components/select-samplings/SelectSamplingsCP'
import { DataImportValidationRequestDTO } from 'submodules/nerit-aquatec-sdk/services/data-import/dtos/request/DataImportValidationRequestDTO'
import { DataImportRequests } from 'submodules/nerit-aquatec-sdk/services/data-import/DataImportRequests'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

/**
 */
export function ModalSamplingImportCP(props: BasicModalCPProps): JSX.Element {

    const [formModel, setFormModel] = useState<SamplingImportFormModel>(new SamplingImportFormModel())
    const formStateManager = useFormStateManager(formModel)

    const importRequest = useRequest<void>('none')
    useEffect(onImportRequestChange, [importRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     */
    function init(): void {

        setFormModel(new SamplingImportFormModel())
    }

    /**
     */
    async function uploadFile(file: File): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!
        const dto: DataImportValidationRequestDTO = {
            identification: formValues.id,
            importationSource: formValues.type,
            samplingCode: formValues.samplingCode
        }
        importRequest.runRequest(DataImportRequests.validate(dto, file))
    }

    /**
     */
    function onImportRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(importRequest, 'Erro ao realizar validação', 'Validado com sucesso', true))
            return

        props.onSave()
    }

    return (
        <ModalCP
            visible={props.visible}
            title={'Importar'}
            onCancel={props.onCancel}
            disableOutsideClickClosing={true}
            noFooter={true}
            singleButton={true}
            width={400}
            top={45}
        >
            <SelectSamplingImportTypeCP
                isRequired={true}
                formStateManager={formStateManager}
                fieldName={'type'}
            />

            <SelectSamplingsCP
                isRequired={true}
                formStateManager={formStateManager}
                fieldName={'samplingCode'}
            />

            <InputCP
                formStateManager={formStateManager}
                fieldName={'id'}
                label={'Identificação'}
                required={true}
            />

            <UploadStepICP
                onUpload={uploadFile}
                loading={importRequest.isAwaiting}
            />
        </ModalCP>
    )
}
