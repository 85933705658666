import React, { useEffect, useState } from 'react'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { SamplingImportTableUtils } from 'modules/samplings/components/table-sampling-import/inner/SamplingImportTableUtils'
import { DataImportResponseDTO } from 'submodules/nerit-aquatec-sdk/services/data-import/dtos/response/DataImportResponseDTO'
import { DataImportRequests } from 'submodules/nerit-aquatec-sdk/services/data-import/DataImportRequests'
import { ModalSamplingImportLog } from 'modules/samplings/components/modal-sampling-import-log/ModalSamplingImportLog'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'

interface ICPProps {
    reloadList: number
}

/**
 * Componente de tabela que contas bancarias
 */
export function TableSamplingImport(props: ICPProps): JSX.Element {

    const [isLogModalVisible, setIsLogModalVisible] = useState<boolean>(false)
    const [selectedBulkOperation, setSelectedBulkOperation] = useState<DataImportResponseDTO>()

    const [reloadList, setReloadList] = useState<number>(props.reloadList)
    useEffect(() => setReloadList(props.reloadList), [props.reloadList])

    /**
     */
    function showLogs(record: DataImportResponseDTO): void {

        setIsLogModalVisible(true)
        setSelectedBulkOperation(record)
    }

    return (
        <>
            <TableFromApiCP<DataImportResponseDTO>
                appearance={{
                    showReloadButton: true
                }}
                shouldLoadData={reloadList}
                apiConfig={{
                    requestConfigTP: DataImportRequests.search
                }}
                columns={SamplingImportTableUtils.getTableColumns(
                    showLogs,
                    () => setReloadList(TableUtils.getReloadNumber())
                )}
            />

            <ModalSamplingImportLog
                visible={isLogModalVisible}
                onClose={() => setIsLogModalVisible(false)}
                log={selectedBulkOperation?.log}
            />
        </>
    )
}
