import React from 'react'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import styled from 'styled-components'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import { RawHtmlCP } from 'submodules/nerit-framework-ui/common/components/raw-html/RawHtmlCP'

interface ICPProps {
    visible: boolean
    onClose: () => void
    log?: string
}

/**
 */
export function ModalSamplingImportLog(props: ICPProps): JSX.Element {

    if (!props.log)
        return <></>

    return (
        <ModalCP
            title={'Logs'}
            top={0}
            width={'100%'}
            visible={props.visible}
            onCancel={props.onClose}
            noFooter={true}
        >
            <LogWrapperSCP>
                <RawHtmlCP html={StringUtils.replaceAll(props.log, '\n', '<br/>')}/>
            </LogWrapperSCP>
        </ModalCP>
    )
}

const LogWrapperSCP = styled.div`
    
`
