import { AquatecSystemApiEnum } from 'submodules/nerit-aquatec-sdk/common/AquatecSystemApiEnum'
import { DataImportResponseDTO } from 'submodules/nerit-aquatec-sdk/services/data-import/dtos/response/DataImportResponseDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { GenericSearchRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/GenericSearchRequestDTO'
import { DataImportValidationRequestDTO } from 'submodules/nerit-aquatec-sdk/services/data-import/dtos/request/DataImportValidationRequestDTO'
import FormData from 'form-data'

export class DataImportRequests {

    public static CONTROLLER_ROOT = 'data-import'
    public static VALIDATE_EP = 'validate'
    public static IMPORT_EP = 'import/:code'
    public static SEARCH_EP = 'search'
    public static DELETE_EP = 'delete/:code'

    static validate = (dto: DataImportValidationRequestDTO, file: any): RequestConfigTP<void> => {

        const formData = new FormData()
        formData.append('file', file)
        formData.append('data', JSON.stringify(dto))

        return {
            baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
            url: `${DataImportRequests.CONTROLLER_ROOT}/${DataImportRequests.VALIDATE_EP}`,
            method: HttpMethodEnum.POST,
            params: formData,
            avoidParamTransformer: true,
            headers: [{
                headerName: 'Content-Type',
                headerValue: 'multipart/form-data',
            }]
        }
    }

    static import = (importationLogValidatedCode: number): RequestConfigTP<void> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${DataImportRequests.CONTROLLER_ROOT}/${DataImportRequests.IMPORT_EP.replace(':code', importationLogValidatedCode.toString())}`,
        method: HttpMethodEnum.POST,
    })

	static search = (dto?: GenericSearchRequestDTO): RequestConfigTP<ListResponseDTO<DataImportResponseDTO>> => ({
		baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
		url: `${DataImportRequests.CONTROLLER_ROOT}/${DataImportRequests.SEARCH_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static delete = (importationLogCode: number): RequestConfigTP<void> => ({
		baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
		url: `${DataImportRequests.CONTROLLER_ROOT}/${DataImportRequests.DELETE_EP.replace(':code', importationLogCode.toString())}`,
		method: HttpMethodEnum.DELETE,
	})

}
