import React, { useEffect } from 'react'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { DataImportRequests } from 'submodules/nerit-aquatec-sdk/services/data-import/DataImportRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ImportationLogStatusEnum } from 'submodules/nerit-aquatec-sdk/services/data-import/enums/ImportationLogStatusEnum'

interface ICPProps {
    importCode: number
    importStatus: ImportationLogStatusEnum
}

/**
 */
export function ButtonSamplingDoImportCP(props: ICPProps): JSX.Element {

    const importRequest = useRequest<void>('none')
    useEffect(onImportRequestChange, [importRequest.isAwaiting])

    /**
     */
    function doImport(): void {

        importRequest.runRequest(DataImportRequests.import(props.importCode))
    }

    /**
     */
    function onImportRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(importRequest, 'Erro ao realizar importação', 'Importado com sucesso', true))
            return

    }

    if (props.importStatus !== ImportationLogStatusEnum.VALIDATION_IMPORT_CONCLUDED)
        return <></>

    return (
        <ButtonCP
            type={'primary'}
            loading={importRequest.isAwaiting}
            onClick={doImport}
            size={'small'}
        >
            Importar
        </ButtonCP>
    )
}
